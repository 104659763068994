import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import qs from "qs";

import store from "@/store";
import {
  axiosGet,
  axiosPost,
  SuccessHandlingOptions,
  ErrorHandlingOptions,
} from "@/utils/axios";

import { Roles } from "@/constants/roles";
import {
  USER_CURRENT_API,
  AUTH_JWT_LOGIN_API,
  AUTH_JWT_LOGOUT_API,
} from "@/constants/api";
import { USER_MODULE_NAME } from "@/constants/store";

interface AuthenticationPayload {
  username: string;
  password: string;
}

@Module({ namespaced: true })
export default class UserModule extends VuexModule {
  public isAuthenticated = false;
  public authToken?: string;
  public authTokenType?: string;

  public id?: string;
  public email?: string;
  public firstName?: string;
  public lastName?: string;
  public role?: Roles;

  @Mutation
  public toggleAuthentication(payload: {
    isAuthenticated: boolean;
    authToken?: string;
    authTokenType?: string;
  }) {
    this.isAuthenticated = payload.isAuthenticated;
    this.authToken = payload.authToken;
    this.authTokenType = payload.authTokenType;
  }

  @Mutation
  public user(payload: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
  }): void {
    if (payload.id) {
      this.id = payload.id;
    }

    if (payload.email) {
      this.email = payload.email;
    }

    if (payload.firstName) {
      this.firstName = payload.firstName;
    }

    if (payload.lastName) {
      this.lastName = payload.lastName;
    }

    if (payload.role) {
      if (payload.role === Roles.ADMINISTRATOR) {
        this.role = Roles.ADMINISTRATOR;
      } else if (payload.role === Roles.REGULAR_USER) {
        this.role = Roles.REGULAR_USER;
      }
    }
  }

  @Action({ rawError: true })
  public async fetchCurrentUser() {
    // Define options for POST request
    const successOptions: SuccessHandlingOptions = { catchCodes: [] };
    const errorOptions: ErrorHandlingOptions = { silentCodes: [401] };

    return axiosGet(USER_CURRENT_API, {}, {}, successOptions, errorOptions)
      .then((response) => {
        // Change current user state
        store.commit(`${USER_MODULE_NAME}/user`, {
          id: response.data.id,
          email: response.data.email,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          role: response.data.role,
        });

        // Return the response
        return response;
      })
      .catch((error) => {
        // In case of error, rethrow
        throw error;
      });
  }

  @Action({ rawError: true })
  public async authenticate(payload: AuthenticationPayload) {
    // Define options for POST request
    const successOptions: SuccessHandlingOptions = { catchCodes: [] };
    const errorOptions: ErrorHandlingOptions = { silentCodes: [401] };

    return axiosPost(
      AUTH_JWT_LOGIN_API,
      qs.stringify(payload),
      {},
      {},
      successOptions,
      errorOptions
    )
      .then((response) => {
        // Toggle authentication
        store.commit(`${USER_MODULE_NAME}/toggleAuthentication`, {
          isAuthenticated: true,
          authToken: response.data.access_token,
          authTokenType: response.data.token_type,
        });

        // Return response
        return response;
      })
      .catch((error) => {
        // Toggle authentication
        store.commit(`${USER_MODULE_NAME}/toggleAuthentication`, {
          isAuthenticated: false,
        });

        // Return error
        throw error;
      });
  }

  @Action({ rawError: true })
  public async deauthenticate() {
    // Define options for POST request
    const successOptions: SuccessHandlingOptions = { catchCodes: [] };
    const errorOptions: ErrorHandlingOptions = { silentCodes: [401] };

    return axiosPost(
      AUTH_JWT_LOGOUT_API,
      {},
      {},
      {},
      successOptions,
      errorOptions
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        // Toggle authentication
        store.commit(`${USER_MODULE_NAME}/toggleAuthentication`, {
          isAuthenticated: false,
        });
      });
  }
}
