import { Roles } from "@/constants/roles";

export default [
  {
    title: "General",
    items: [
      {
        to: "/",
        icon: "desktop-mac",
        label: "Dashboard",
      },
    ],
  },
  {
    title: "Administration",
    roles: [Roles.ADMINISTRATOR],
    items: [
      {
        to: "/users",
        icon: "account-group",
        label: "Users",
      },
    ],
  },
];
