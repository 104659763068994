
import { namespace } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

import { USER_MODULE_NAME, LAYOUT_MODULE_NAME } from "@/constants/store";

import NavBarMenu from "@/components/navbar/NavBarMenu.vue";
import UserAvatar from "@/components/UserAvatar.vue";

const Layout = namespace(LAYOUT_MODULE_NAME);
const User = namespace(USER_MODULE_NAME);

@Component({
  components: {
    UserAvatar,
    NavBarMenu,
  },
})
export default class NavBarComponent extends Vue {
  private isMenuActive = false;

  get asideMobileIcon(): string {
    return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
  }

  get menuToggleIcon(): string {
    return this.isMenuActive ? "close" : "dots-vertical";
  }

  @Layout.State
  private isAsideMobileExpanded!: boolean;

  @Layout.State
  private isNavBarVisible!: boolean;

  @User.State
  private email!: string;

  @User.State
  private firstName!: string;

  @User.State
  private lastName!: string;

  mounted(): void {
    this.$router.afterEach(() => {
      this.isMenuActive = false;
    });
  }

  asideToggleMobile(): void {
    this.$store.commit(`${LAYOUT_MODULE_NAME}/asideMobileStateToggle`);
  }

  asideDesktopOnlyToggle(): void {
    this.$store.dispatch(`${LAYOUT_MODULE_NAME}/asideDesktopOnlyToggle`);
  }

  menuToggle(): void {
    this.isMenuActive = !this.isMenuActive;
  }

  logout(): void {
    // Use user module code to deauthenticate
    this.$store.dispatch(`${USER_MODULE_NAME}/deauthenticate`, {});

    // Redirect to login page
    this.$router.push({ name: "login" });
  }
}
