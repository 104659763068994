import { API_URL } from "@/constants";

export const FOOTER_API = `${API_URL}/footer`;

export const AUTH_JWT_LOGIN_API = `${API_URL}/auth/jwt/login`;
export const AUTH_JWT_LOGOUT_API = `${API_URL}/auth/jwt/logout`;

export const USERS_API = `${API_URL}/users`;
export const USER_CURRENT_API = `${API_URL}/users/me`;
export const USER_API = (userId) => `${USERS_API}/${userId}`;
