
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    AsideMenuList: () => import("@/components/aside-menu/AsideMenuList.vue"),
  },
})
export default class AsideMenuItemComponent extends Vue {
  @Prop({ required: true, type: Object })
  private readonly item;

  isDropdownActive = false;

  private get componentIs(): string {
    return this.item.to ? "router-link" : "a";
  }

  private get hasDropdown(): boolean {
    return !!this.item.menu;
  }

  private get dropdownIcon(): string {
    return this.isDropdownActive ? "minus" : "plus";
  }

  @Emit("menu-click")
  menuClick(): void {
    if (this.hasDropdown) {
      this.isDropdownActive = !this.isDropdownActive;
    }
  }
}
