import { SnackbarProgrammatic as Snackbar } from "buefy";
import axios, { AxiosResponse, AxiosError, AxiosRequestHeaders } from "axios";

import store from "@/store";
import router from "@/router";
import { USER_MODULE_NAME } from "@/constants/store";

// Define options interface
interface ResponseHandlingOptions {
  silentCodes?: Array<number>;
  catchCodes?: Array<number>;
  hasMessageSnackbar?: boolean;
  snackbarType?: string;
  messageCallable?: (code: number, url: string) => string;
}

interface ErrorHandlingOptions extends ResponseHandlingOptions {
  enableRetryButton?: boolean;
}

type SuccessHandlingOptions = ResponseHandlingOptions;

// Constants
const range = (start, end) =>
  Array.from({ length: end - start }, (v, k) => k + start);
const ERROR_CODES: Array<number> = range(400, 600);
const SUCCESS_CODES: Array<number> = range(200, 400);

// Create axios interceptors
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      if (router.currentRoute.name !== "login") {
        router.push({ name: "login" });
      }
    }

    return Promise.reject(error);
  }
);

// In case of unknown error (does not use error)
// eslint-disable-next-line
function _handleUnknownError(error: AxiosError | Error) {
  // Open a snackbar
  Snackbar.open({
    message: "Unknown error occured",
    type: "is-danger",
    position: "is-top",
  });
}

// HTTP responses handlers (more configurable interceptors-like methods)
function _handleAxiosError(
  error: AxiosError,
  options: ErrorHandlingOptions = {}
) {
  // Set all default values
  if (options.silentCodes === undefined) {
    options.silentCodes = [];
  }

  if (options.catchCodes === undefined) {
    options.catchCodes = ERROR_CODES;
  }

  if (options.hasMessageSnackbar === undefined) {
    options.hasMessageSnackbar = true;
  }

  if (options.messageCallable === undefined) {
    options.messageCallable = (code: number, url: string) =>
      `An error occured (HTTP ${code} - ${url})`;
  }

  if (options.snackbarType === undefined) {
    options.snackbarType = "is-danger";
  }

  // If error is an actual Axios error (with response and request)
  if (error.response && error.request) {
    // Extract HTTP status from response
    const status = error.response.status;

    if (
      !options.silentCodes.includes(status) &&
      options.catchCodes.includes(status) &&
      options.hasMessageSnackbar
    ) {
      Snackbar.open({
        message: options.messageCallable(
          error.response.status,
          error.request.responseURL
        ),
        type: options.snackbarType,
        position: "is-top",
      });
    }
  } else {
    _handleUnknownError(error);
  }
}

function _handleSuccess(
  response: AxiosResponse,
  options: SuccessHandlingOptions = {}
) {
  // Set all default values
  if (options.silentCodes === undefined) {
    options.silentCodes = [];
  }

  if (options.catchCodes === undefined) {
    options.catchCodes = SUCCESS_CODES;
  }

  if (options.hasMessageSnackbar === undefined) {
    options.hasMessageSnackbar = false;
  }

  if (options.messageCallable === undefined) {
    // eslint-disable-next-line
    options.messageCallable = (code: number, url: string) =>
      `Process successfully ended !`;
  }

  if (options.snackbarType === undefined) {
    options.snackbarType = "is-success";
  }

  // If error is an actual Axios error (with response and request)
  if (response && response.status && response.request) {
    // Extract HTTP status from response
    const status = response.status;

    if (
      !options.silentCodes.includes(status) &&
      options.catchCodes.includes(status) &&
      options.hasMessageSnackbar
    ) {
      Snackbar.open({
        message: options.messageCallable(
          response.status,
          response.request.responseURL
        ),
        type: options.snackbarType,
        position: "is-top",
      });
    }
  }
}

function getAuthenticatedHeaders() {
  // Return authenticated headers if user is authentified
  if (store.state[USER_MODULE_NAME].isAuthenticated) {
    const token = store.state[USER_MODULE_NAME].authToken;
    const tokenType = store.state[USER_MODULE_NAME].authTokenType;

    // Return headers
    return {
      Authorization: `${tokenType} ${token}`,
    };
  }

  return {};
}

// Core methods
async function axiosGet(
  url: string,
  params: object = {},
  headers: object = {},
  responseOptions: SuccessHandlingOptions = {},
  errorOptions: ErrorHandlingOptions = {}
) {
  // Add authenticated token to headers if it exists
  headers = {
    ...getAuthenticatedHeaders(),
    ...headers,
  };

  return await axios
    .get(url, { params: params, headers: headers as AxiosRequestHeaders })
    .then((response: AxiosResponse) => {
      // Handle success
      _handleSuccess(response, responseOptions);

      // Return response from API
      return response;
    })
    .catch((error: AxiosError | Error) => {
      if (axios.isAxiosError(error)) {
        // Process a usual axios error
        _handleAxiosError(error, errorOptions);
      } else {
        // Otherwise, just a stock error
        _handleUnknownError(error);
      }

      // Return the error
      throw error;
    });
}

async function axiosPost(
  url: string,
  body: object | string,
  params: object = {},
  headers: object = {},
  responseOptions: SuccessHandlingOptions = {},
  errorOptions: ErrorHandlingOptions = {}
) {
  // Add authenticated token to headers if it exists
  headers = {
    ...getAuthenticatedHeaders(),
    ...headers,
  };

  return axios
    .post(url, body, {
      params: params,
      headers: headers as AxiosRequestHeaders,
    })
    .then((response: AxiosResponse) => {
      // Handle success
      _handleSuccess(response, responseOptions);

      // Return response
      return response;
    })
    .catch((error: AxiosError | Error) => {
      if (axios.isAxiosError(error)) {
        // Process a usual axios error
        _handleAxiosError(error, errorOptions);
      } else {
        // Otherwise, just a stock error
        _handleUnknownError(error);
      }

      // Return error
      throw error;
    });
}

async function axiosPatch(
  url: string,
  body: object | string,
  params: object = {},
  headers: object = {},
  responseOptions: SuccessHandlingOptions = {},
  errorOptions: ErrorHandlingOptions = {}
) {
  // Add authenticated token to headers if it exists
  headers = {
    ...getAuthenticatedHeaders(),
    ...headers,
  };

  return axios
    .patch(url, body, {
      params: params,
      headers: headers as AxiosRequestHeaders,
    })
    .then((response: AxiosResponse) => {
      // Handle success
      _handleSuccess(response, responseOptions);

      // Return response
      return response;
    })
    .catch((error: AxiosError | Error) => {
      if (axios.isAxiosError(error)) {
        // Process a usual axios error
        _handleAxiosError(error, errorOptions);
      } else {
        // Otherwise, just a stock error
        _handleUnknownError(error);
      }

      // Return error
      throw error;
    });
}

export { SuccessHandlingOptions, ErrorHandlingOptions };
export { axiosGet, axiosPost, axiosPatch };
