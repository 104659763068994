import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

import store from "@/store";

import {
  axiosGet,
  SuccessHandlingOptions,
  ErrorHandlingOptions,
} from "@/utils/axios";
import { FOOTER_MODULE_NAME } from "@/constants/store";
import { FOOTER_API } from "@/constants/api";

@Module({ namespaced: true })
export default class FooterModule extends VuexModule {
  public webVersion?: string;
  public apiVersion?: string;
  public pkgVersion?: string;
  public dbRevision?: string;

  @Mutation
  public footer(payload: {
    webVersion: string;
    apiVersion: string;
    pkgVersion: string;
    dbRevision: string;
  }) {
    this.webVersion = payload.webVersion;
    this.apiVersion = payload.apiVersion;
    this.pkgVersion = payload.pkgVersion;
    this.dbRevision = payload.dbRevision;
  }

  @Action
  public async fetchFooterData(): Promise<void> {
    // Define options for POST request
    const successOptions: SuccessHandlingOptions = { catchCodes: [] };
    const errorOptions: ErrorHandlingOptions = { silentCodes: [401] };

    axiosGet(FOOTER_API, {}, {}, successOptions, errorOptions).then(
      (response) => {
        store.commit(`${FOOTER_MODULE_NAME}/footer`, {
          webVersion: "UNKNOWN",
          apiVersion: response.data.api,
          pkgVersion: response.data.pkg,
          dbRevision: response.data.db,
        });
      }
    );
  }
}
