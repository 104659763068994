import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import store from "@/store";

import Home from "@/views/Home.vue";
import { USER_MODULE_NAME } from "@/constants/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
    path: "/",
    name: "home",
    component: Home,
  },
  {
    meta: {
      title: "Users",
      requiresAuth: true,
    },
    path: "/users",
    name: "Users",
    component: () => import("@/views/users/UsersList.vue"),
  },
  {
    path: "/full-page",
    component: () =>
      import(/* webpackChunkName: "full-page" */ "@/views/FullPage.vue"),
    children: [
      {
        meta: {
          title: "Login",
          requiresAuth: false,
        },
        path: "/login",
        name: "login",
        component: () =>
          import(
            /* webpackChunkName: "full-page" */ "@/views/full-page/Login.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

/* Check if user is logged in before routing */
router.beforeEach(async (to, from, next) => {
  // Check if requested route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Extract authentication status from store
    const authenticated = store.state[USER_MODULE_NAME].isAuthenticated;

    // If not authenticated redirect to login page
    if (!authenticated) {
      next({
        name: "login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      // If authenticated, process to requested page
      next();
    }
  } else {
    // If not authentication is required, process to requested page
    next();
  }
});

/* Default title tag */
const defaultDocumentTitle = "Default dashboard";

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach((to) => {
  store.commit("LayoutModule/asideMobileStateToggle", false);

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }
});

export default router;
