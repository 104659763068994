
import { Component, Vue } from "vue-property-decorator";

import menu from "@/menu";

import NavBar from "@/components/navbar/NavBar.vue";
import AsideMenu from "@/components/aside-menu/AsideMenu.vue";
import FooterBar from "@/components/FooterBar.vue";

@Component({
  components: {
    NavBar,
    AsideMenu,
    FooterBar,
  },
})
export default class AppBaseView extends Vue {
  menu: Array<unknown> = menu;
}
