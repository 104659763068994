/* Styles */
import "@/scss/main.scss";

/* Core */
import Vue from "vue";
import Buefy from "buefy";

/* Router & Store */
import router from "@/router";
import store from "@/store";

/* VModal component */
import VModal from "vue-js-modal";

/* Vue. Main component */
import App from "@/App.vue";

Vue.config.productionTip = false;

Vue.use(Buefy);
Vue.use(VModal);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
