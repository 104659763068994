
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import AsideMenuItem from "@/components/aside-menu/AsideMenuItem.vue";

@Component({
  components: {
    AsideMenuItem,
  },
})
export default class AsideMenuListComponent extends Vue {
  @Prop(Boolean)
  private readonly isSubmenuList;

  @Prop({ type: Array, default: () => [] })
  private readonly menu;

  @Emit("menu-click")
  menuClick(item) {
    return item;
  }
}
