
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { FOOTER_MODULE_NAME, LAYOUT_MODULE_NAME } from "@/constants/store";

const Layout = namespace(LAYOUT_MODULE_NAME);
const Footer = namespace(FOOTER_MODULE_NAME);

@Component({})
export default class FooterBarComponent extends Vue {
  private year: number = new Date().getFullYear();

  @Layout.State
  private readonly isFooterBarVisible!: boolean;

  @Footer.State
  private readonly pkgVersion!: string;

  @Footer.State
  private readonly apiVersion!: string;

  @Footer.State
  private readonly webVersion!: string;

  @Footer.State
  private readonly dbRevision!: string;

  created(): void {
    this.$store.dispatch(`${FOOTER_MODULE_NAME}/fetchFooterData`);
  }
}
