
import { namespace } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";

import { Roles, ALL_ROLES } from "@/constants/roles";
import { LAYOUT_MODULE_NAME, USER_MODULE_NAME } from "@/constants/store";

import AsideMenuList from "@/components/aside-menu/AsideMenuList.vue";

const User = namespace(USER_MODULE_NAME);
const Layout = namespace(LAYOUT_MODULE_NAME);

@Component({
  components: {
    AsideMenuList,
  },
})
export default class AsideMenuComponent extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly menu;

  private ALL_ROLES = ALL_ROLES;

  @Layout.State
  public isAsideVisible!: boolean;

  @User.State
  public role!: Roles;

  mounted(): void {
    this.$router.afterEach(() => {
      this.$store.dispatch("LayoutModule/asideDesktopOnlyToggle", false);
    });
  }

  asideToggleDesktopOnly() {
    this.$store.dispatch("LayoutModule/asideDesktopOnlyToggle");
  }

  menuClick() {
    // console.log("menu-click");
  }
}
