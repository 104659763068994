import Vue from "vue";
import Vuex from "vuex";

import FooterModule from "@/store/modules/footer";
import LayoutModule from "@/store/modules/layout";
import UserModule from "@/store/modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    FooterModule,
    LayoutModule,
    UserModule,
  },
});
