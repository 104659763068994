
import { Component, Vue } from "vue-property-decorator";

import TitleBar from "@/components/TitleBar.vue";
import HeroBar from "@/components/HeroBar.vue";

@Component({
  components: {
    HeroBar,
    TitleBar,
  },
})
export default class HomeView extends Vue {
  titleStack: Array<string> = ["Admin", "Dashboard"];
}
